<!--
 * @Author: your name
 * @Date: 2020-11-04 10:34:35
 * @LastEditTime: 2021-05-11 11:10:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\productionManagement\logisticsMonitoring\actionsPanel.vue
-->
<template>
    <div class="logist-actionsPanel" :class="{'logist-actionsPanel-hidden': open===true}">
        <!-- 顶部tab切换 -->
        <div class="tab-con">
            <div class="tab-conta">
                <div class="tabs">
                    <div
                        class="item"
                        v-for="(item, index) in tabs"
                        :key="`tab_${index}`"
                        :class="{'active': activeTab===index}"
                        @click="handleActiveTab(index)"
                    >
                        <span class="iconfont" :class="item.className"></span>
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="contents">
                    <!-- 在施工地 -->
                    <div class="item item1" v-if="activeTab===0">
                        <ul>
                            <li
                                v-for="(item, index) in constructionTabs"
                                :key="`construction_${index}`"
                                :class="{'active': constructionActive===index}"
                                class="tab-item"
                                @click="hanleConstructionActive(index,item)"
                            >
                                <p class="num">
                                    {{ item.num }}
                                </p>
                                <p>
                                    <span class="iconfont iconqizi"></span>
                                    <span>{{ item.name }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <!-- 运输车辆 -->
                    <div class="item item2" v-if="activeTab===1">
                        <ul>
                            <li
                                v-for="(item, index) in carTabs"
                                :key="`car_${index}`"
                                :class="{'active': carActive===index}"
                                @click="handleCarActive(index, item.sortNum)"
                                class="tab-item"
                            >
                                <p class="num">
                                    {{ item.num }}
                                </p>
                                <p>
                                    <span class="iconfont iconjiaobanche"></span>
                                    <span>{{ item.name }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <!-- 异常监控 -->
                    <div class="item item3" v-if="activeTab===2">
                        <span class="exceptionSetBtn iconfont iconshezhi" @click="openExceptionSet"></span>
                        <div class="exception-rules-dialog" v-show="showExceptionSet">
                            <div class="rules-content">
                                <h2>车辆监控预警规则配置</h2>
                                <div class="rules-item">
                                    <label>停车超时：>=</label>
                                    <el-input v-model="exceptionVal[1]"></el-input>
                                    <label>小时</label>
                                </div>
                                <div class="rules-item">
                                    <label>行驶速度：>=</label>
                                    <el-input v-model="exceptionVal[2]"></el-input>
                                    <label>KM/h</label>
                                </div>
                                <div class="rules-item">
                                    <label>运输超时：>=</label>
                                    <el-input v-model="exceptionVal[8]"></el-input>
                                    <label>小时</label>
                                </div>
                                <h2>工地监控预警规则配置</h2>
                                <div class="rules-item">
                                    <label>工地缺车：>=</label>
                                    <el-input v-model="exceptionVal[9]"></el-input>
                                    <label>小时</label>
                                </div>
                                <div class="rules-item">
                                    <label>工地压车：>=</label>
                                    <el-input v-model="exceptionVal[6]"></el-input>
                                    <label>辆</label>
                                </div>
                                <div class="rules-item">
                                    <label>卸料超时：>=</label>
                                    <el-input v-model="exceptionVal[10]"></el-input>
                                    <label>小时</label>
                                </div>
                                <div class="btns">
                                    <a class="btn-save" @click="saveExceptionVal">保存</a>
                                    <a class="btn-close" @click="exceptionSetClose">关闭</a>
                                </div>
                            </div>
                        </div>
                        <div class="abnormal-box carWatch">
                            <div class="title">
                                车辆监控
                            </div>
                            <ul>
                                <li
                                    v-for="(v, vIndex) in abnormalTabs[0]"
                                    :key="`abnormal_${vIndex}`"
                                    :class="{'active': abnormalActive===v.sortNum}"
                                    class="tab-item"
                                    @click="clickAbnormal(v.sortNum)"
                                >
                                    <p class="num">
                                        {{ v.num }}
                                    </p>
                                    <p>
                                        <span class="iconfont" :class="v.className"></span>
                                        <span>{{ v.name }}</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="abnormal-box projectWatch">
                            <div class="title">
                                工地监控
                            </div>
                            <ul>
                                <li
                                    v-for="(v, vIndex) in abnormalTabs[1]"
                                    :key="`abnormal__${vIndex}`"
                                    :class="{'active': abnormalActive===v.sortNum}"
                                    class="tab-item"
                                    @click="clickAbnormal(v.sortNum)"
                                >
                                    <p class="num">
                                        {{ v.num }}
                                    </p>
                                    <p>
                                        <span class="iconfont" :class="v.className"></span>
                                        <span>{{ v.name }}</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容区域 -->
        <div
            class="main"
            :class="{doubleRow:radio===2, close: open===true}"
            v-if="activeTab!==2"
        >
            <span
                class="open-btn"
                :class="{'close-btn':open===true}"
                @click="open=!open"
            ></span>
            <div class="tabs">
                <div
                    class="item"
                    v-for="(item, index) in mainTabs"
                    :key="`tab_${index}`"
                    :class="{'active': mainActiveTab===index}"
                    @click="handleMainActiveTab(index, item)"
                >
                    <span>{{ item.name }}</span>
                </div>
            </div>
            <div class="content">
                <!-- 蚂蚁线 -->
                <div class="antline" v-if="mainActiveTab===0">
                    <div class="top">
                        <el-input
                            placeholder="请输入任务编号、工程名称检索"
                            suffix-icon="el-icon-search"
                            v-model="searchVal"
                            @change="handleSearchAntline"
                            clearable
                        >
                        </el-input>
                        <el-radio-group v-model="radio">
                            <el-radio :label="1">
                                单列
                            </el-radio>
                            <el-radio :label="2">
                                双列
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div class="antline-con" :class="{'haveBackCar': activeTab===1}">
                        <ul>
                            <li
                                :class="{'active': activeWorkOrder===item.task_number}"
                                v-for="(item, index) in workOrderList"
                                :key="item.task_number"
                                @click="clickWorkOrder(item.task_number)"
                            >
                                <h4>
                                    <span class="order-num">{{ index+1 }}</span>
                                    <span>{{ item.task_number }}；</span>
                                    <span
                                        class="project_name"
                                        :title="item.project_name"
                                        v-if="item.project_name"
                                    >{{ item.project_name|handleProjectName }}；</span>
                                    <span v-if="item.place">{{ item.place }}；</span>
                                    <span v-if="item.strength_grade">{{ item.strength_grade }}；</span>
                                    <span>{{ item.grand_total_quantity }}</span>
                                </h4>
                                <div class="line-con">
                                    <div>
                                        <i class="start-icon"></i>
                                        <i class="end-icon"></i>
                                        <span class="start-num">0km</span>
                                        <span class="middle-num"> {{ item.half_haul_distance }}km</span>
                                        <span class="end-num">{{ item.haul_distance_show }}km</span>
                                        <template
                                            v-for="(car) in item.cars"
                                        >
                                            <div
                                                :class="getClass(car)"
                                                :style="{left:computeDis(item,car)+'%',
                                                         marginLeft: parseInt(computeDis(item,car))!=0?'-0.14rem':'0.03rem'}"
                                                :key="`${item.project_code + car.plate_small_number}`"
                                                v-if="car.orientation===0&&(car.status===1||car.status===4||car.status===5||car.status===6)"
                                            >
                                                {{ car.plate_small_number }}
                                            </div>
                                            <div
                                                :class="getClass(car)"
                                                :style="{right:computeDis(item,car)+'%',
                                                         marginRight: parseInt(computeDis(item,car))!=0?'-0.14rem':'0.03rem'}"
                                                :key="`${item.project_code + car.plate_small_number}`"
                                                v-if="car.orientation===1&&(car.status===1||car.status===4||car.status===5||car.status===6)"
                                            >
                                                {{ car.plate_small_number }}
                                            </div>
                                        </template>
                                    </div>
                                    <div>
                                        <template v-for="(car, carindex) in item.cars">
                                            <span
                                                :class="getClass(car)"
                                                v-if="car.status===2||car.status===3"
                                                :key="`${car.plate_number}${carindex}`"
                                            >{{ car.plate_small_number }}</span>
                                        </template>
                                    </div>
                                    <div>
                                        <div class="parent">
                                            <div class="per" :style="{height: getFinishInner(item)}"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="bottom-scale">
                        <template v-if="activeTab===1">
                            <div class="title">
                                <span class="left"></span>
                                <span>车辆返场</span>
                                <span class="right"></span>
                            </div>
                            <div class="scale-con">
                                <div class="scale">
                                    <span
                                        v-for="(item, index) in scaleArr"
                                        :key="`scale_${index}`"
                                        :class="{'height8':item%5===0, 'height10':item%10===0}"
                                    >
                                        <i class="number" v-if="item%10===0">
                                            <template v-if="item!==60">
                                                {{ item/2 }}
                                            </template>
                                            <template v-else>
                                                ...
                                            </template>
                                        </i>
                                    </span>
                                    <template v-for="(item, index) in carList">
                                        <div
                                            :class="getCarClass(item)"
                                            :key="`car_${index}`"
                                            :style="getCarLeft(item)"
                                        ></div>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <div class="legends">
                            <div class="item item0">
                                <span class="pre-icon"></span>
                                <span>运输中</span>
                            </div>
                            <div class="item item1">
                                <span class="pre-icon"></span>
                                <span>返场中</span>
                            </div>
                            <div class="item item2">
                                <span class="pre-icon"></span>
                                <span>停止</span>
                            </div>
                            <div class="item item3">
                                <span class="pre-icon"></span>
                                <span>卸料</span>
                            </div>
                            <div class="item item4">
                                <span class="pre-icon"></span>
                                <span>在工地</span>
                            </div>
                            <div class="item item5">
                                <span class="pre-icon"></span>
                                <span>无信号</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 车辆列表 -->
                <div class="carlist" v-else>
                    <div class="tabs" v-if="activeTab===0">
                        <div
                            class="tab"
                            v-for="(item, index) in transTabs"
                            :key="`transTab_${index}`"
                            :class="{active: transActive===item.status}"
                            @click="handleTransActive(item.status)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="search-con" :style="{marginTop:activeTab===1?'.1rem':'0'}">
                        <el-input
                            placeholder="请输入"
                            suffix-icon="el-icon-search"
                            v-model="transSearchVal"
                            @change="handleCarlistPrj"
                            clearable
                        >
                        </el-input>
                    </div>
                    <div class="list-con" :style="{height:activeTab===0?'calc(100% - 1rem)':'calc(100% - .7rem)'}">
                        <el-table
                            :data="transList"
                            stripe
                            style="width: 100%"
                            height="100%"
                            :highlight-current-row="true"
                            @row-click="clickTransList"
                        >
                            <el-table-column
                                prop="plate_small_number"
                                label="小号"
                                width="80"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="speed"
                                width="80"
                            >
                                <template slot="header">
                                    速度<br>(km/h)
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="to_distance"
                            >
                                <template slot="header">
                                    距离<br>(km)
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="to_time"
                            >
                                <template slot="header">
                                    时间<br>(h)
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="发货单号"
                            >
                                <template slot-scope="scope">
                                    <span
                                        class="pcb-num"
                                        @click.stop="transInfo(scope.row.pcb_number)"
                                    >{{ scope.row.pcb_number|handlePcbNum }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div v-else class="abnormal-main">
            <el-input
                placeholder="请输入关键词(车牌号、车号、异常内容)"
                suffix-icon="el-icon-search"
                v-model="abnormalSearch"
                @change="handleAbnormalSearch"
                clearable
            >
            </el-input>
            <div class="abnormal-list infinite-list" v-infinite-scroll="load" style="overflow:auto">
                <ul
                    v-for="item in tableData"
                    :key="item.id"
                >
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.error_content"
                        placement="left"
                        popper-class="abnormalinfo-tooltip"
                    >
                        <li>
                            <span>
                                <span class="plate-small-num">{{ item.no }}</span>
                                <span>{{ item.error_name }}</span>
                            </span>
                            <span class="time">
                                <span class="dot"></span>
                                <span>{{ item.error_datetime }}</span>
                            </span>
                        </li>
                    </el-tooltip>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
export default {
    components: {},
    props: {
        projectsStatistics: {
            type: Object,
        },
        carStatistics: {
            type: Object,
        },
        currentStationId: {
            type: String,
        },
        projectsAddress: {
            type: Array,
        },
    },
    data() {
        return {
            // 顶部tab切换
            tabs: [
                { name: '在施工地:', className: 'iconqizi' },
                { name: '运输车辆:', className: 'iconjiaobanche' },
                { name: '异常监控:', className: 'iconyichang' },
            ],
            activeTab: 0,
            // 在施工地类型
            constructionTabs: [
                { name: '运输中', className: 'iconqizi', num: 0, sortNum: 4, projectCodeList: [] },
                { name: '未完成', className: 'iconjiaobanche', num: 0, sortNum: 3, projectCodeList: [] },
                { name: '未运输', className: 'iconyichang', num: 0, sortNum: 2, projectCodeList: [] },
            ],
            projectCodeList: [],
            projectCodeListAll: [],
            constructionActive: 0,
            // 运输车辆类型
            carTabs: [
                { name: '运输中', className: 'iconqizi', num: 0, sortNum: 2 },
                { name: '返场中', className: 'iconjiaobanche', num: 0, sortNum: 3 },
                { name: '在工地', className: 'iconyichang', num: 0, sortNum: 5 },
                { name: '未运输', className: 'iconyichang', num: 0, sortNum: 4 },
            ],
            carActive: -1,
            // 异常监控类型
            abnormalTabs: [
                // [
                //     { name: '停车超时', className: 'iconyichang', num: 0, sortNum: 1 },
                //     { name: '行驶超速', className: 'iconyichang', num: 0, sortNum: 2 },
                //     { name: '路线偏离', className: 'iconyichang', num: 0, sortNum: 3 },
                //     { name: '罐反转', className: 'iconyichang', num: 0, sortNum: 4 },
                // ],
                // [
                //     { name: '油耗异常', className: 'iconyichang', num: 0, sortNum: 5 },
                //     { name: '压车异常', className: 'iconyichang', num: 0, sortNum: 6 },
                //     { name: '其他异常', className: 'iconyichang', num: 0, sortNum: 7 },
                // ],
                [
                    { name: '停车超时', className: 'icontingchechang1', num: 0, sortNum: 1 },
                    { name: '行驶超速', className: 'iconchaosu', num: 0, sortNum: 2 },
                    { name: '路线偏离', className: 'iconhangxian', num: 0, sortNum: 3 },
                    { name: '运输超时', className: 'iconshijian1', num: 0, sortNum: 8 },

                    { name: '油耗异常', className: 'iconshuiyoux-', num: 0, sortNum: 5 },
                    { name: '罐车反转', className: 'iconjiaobanche', num: 0, sortNum: 4 },
                    { name: '其他异常', className: 'iconinfo', num: 0, sortNum: 7 },
                ],
                [
                    { name: '工地缺车', className: 'iconyichang', num: 0, sortNum: 9 },
                    { name: '压车异常', className: 'iconyichang', num: 0, sortNum: 6 },
                    { name: '卸料超时', className: 'iconyichang', num: 0, sortNum: 10 },
                ],
            ],
            abnormalActive: -1,
            // 蚂蚁线/车辆列表切换
            mainTabs: [
                { name: '蚂蚁线' },
                { name: '车辆列表' },
            ],
            mainActiveTab: 0,
            searchVal: '',
            radio: 1,
            scaleArr: [],
            // 运输单列表tab
            transTabs: [
                { name: '全部', status: -1 },
                { name: '运输中', status: 0 },
                { name: '已到达', status: 1 },
                { name: '卸料完成', status: 2 },
            ],
            transActive: -1,
            activeList: 0,
            // 展开收起
            open: false,
            // 蚂蚁线
            workOrderList: [],
            activeWorkOrder: '',
            // 运输单列表
            transList: [],
            // 车辆列表
            carList: [],
            // 异常监控-搜索条件
            abnormalSearch: '',
            // 异常记录列表
            tableData: [],
            abnormalPage: {
                currentPage: 1,
                pageSize: 20,
            },
            // 车辆列表-模糊搜索条件
            transSearchVal: '',

            // 异常监控设置弹框
            showExceptionSet: false,
            exceptionVal: {
                1: null, // 停车超时
                2: null, // 超速报警
                8: null, // 运输超时
                9: null, // 工地缺车
                6: null, // 压车异常
                10: null, // 卸料超时
            },
        };
    },
    watch: {
        projectsAddress: {
            handler: function (val) {
                this.constructionTabs.forEach(item => {
                    item.num = 0;
                });
                val.map(item => {
                    if (item.status === 1) {
                        this.constructionTabs[2].num += 1;
                        this.constructionTabs[2].projectCodeList.push(item.project_code);
                    } else if (item.status === 3) {
                        this.constructionTabs[0].num += 1;
                        this.constructionTabs[0].projectCodeList.push(item.project_code);
                    } else {
                        this.constructionTabs[1].num += 1;
                        this.constructionTabs[1].projectCodeList.push(item.project_code);
                    }
                    this.projectCodeListAll.push(item.project_code);
                });
                this.tabs[0].name = '在施工地：' + val.length;
                this.projectCodeList = this.constructionTabs[0].projectCodeList;
                this.handleCarlistPrj();
            },
            deep: true,
        },
        carStatistics(val) {
            this.tabs[1].name = '运输车辆：' + val.all;
            this.carTabs.forEach(item => {
                if (item.name === '运输中') {
                    item.num = val.trans;
                } else if (item.name === '返场中') {
                    item.num = val.back;
                } else if (item.name === '在工地') {
                    item.num = val.onSite;
                } else if (item.name === '未运输') {
                    item.num = val.notWork;
                }
            });
        },
        currentStationId(val) {
            if (val) {
                this.handleProductionList();
            }
        },
    },
    filters: {
        // 蚂蚁线工程名称截取
        handleProjectName: function (val) {
            let str = '';
            if (val) {
                if (val.length > 12) {
                    str = val.substr(0, 12) + '...';
                }
            }
            return str;
        },
        // 排车表编号截取
        handlePcbNum(val) {
            let str = '';
            if (val) {
                str = val.substr(7, val.length);
            }
            return str;
        },
    },
    computed: {},
    created() {},
    mounted() {
        this.init();
        eventBus.$on('TaskLineChangeMsg', data => {
            if (data) {
                this.taskLineChange(data);
            }
        });
        eventBus.$on('MapCarChangeMsg', data => {
            const userTenantId = this.$takeTokenParameters('TenantId');
            const curMixStationCode = this.currentStationId;
            if (data && data.tenant_id === userTenantId && data.mixstation_code === curMixStationCode) {
                this.mapCarChange(data);
            }
        });
    },
    beforeDestroy() {
        eventBus.$off('TaskLineChangeMsg');
        eventBus.$off('MapCarChangeMsg');
    },
    methods: {
        // 高德坐标转百度（传入经度、纬度）
        bd_encrypt(gg_lng, gg_lat) {
            const X_PI = Math.PI * 3000.0 / 180.0;
            const x = gg_lng;
            const y = gg_lat;
            const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
            const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
            const bd_lng = z * Math.cos(theta) + 0.0065;
            const bd_lat = z * Math.sin(theta) + 0.006;
            return {
                bd_lat: bd_lat,
                bd_lng: bd_lng,
            };
        },
        init() {
            // 底部刻度线初始化渲染
            for (let i = 0; i <= 60; i++ ) {
                this.scaleArr.push(i);
            }
            this.tabs[0].name = '在施工地：' + this.projectsStatistics.all;
            this.tabs[1].name = '运输车辆：' + this.carStatistics.all;
            this.constructionTabs.forEach(item => {
                if (item.name === '运输中') {
                    item.num = this.projectsStatistics.trans;
                } else if (item.name === '未完成') {
                    item.num = this.projectsStatistics.notFinished;
                } else if (item.name === '未运输') {
                    item.num = this.projectsStatistics.notWork;
                }
            });
            this.carTabs.forEach(item => {
                if (item.name === '运输中') {
                    item.num = this.carStatistics.trans;
                } else if (item.name === '返场中') {
                    item.num = this.carStatistics.back;
                } else if (item.name === '在工地') {
                    item.num = this.carStatistics.onSite;
                } else if (item.name === '未运输') {
                    item.num = this.carStatistics.notWork;
                }
            });
            this.handleExceptionsGroup();
            this.handleExceptions();
        },
        // 任务单改变
        async taskLineChange(data) {
            // 生产状态 0 未排 1 待生产2 生产中 3 已完成 4 暂停 5 已关闭 6 已取消
            // 只更新生产中的
            const res = this.workOrderList.filter(item => item.task_number === data.task_number);
            if (data.station === this.currentStationId) {
                if (data.product_status === 2) {
                    if (res.length > 0) {
                        this.workOrderList = this.workOrderList.map(item => {
                            if (data.task_number === item.task_number) {
                                const cars = JSON.parse(JSON.stringify(item)).cars;
                                item = data;
                                item.haul_distance_show = (data.haul_distance / 1000).toFixed(2);
                                item.half_haul_distance = (data.haul_distance / 2 / 1000).toFixed(2);
                                item.cars = cars;
                            }
                            return item;
                        });
                    } else {
                        data.haul_distance_show = (data.haul_distance / 1000).toFixed(2);
                        data.half_haul_distance = (data.haul_distance / 2 / 1000).toFixed(2);
                        const cars = await this.getTaskCars(data.task_number);
                        if (cars) {
                            data.cars = cars;
                            this.workOrderList.push(data);
                        } else {
                            data.cars = [];
                            this.workOrderList.push(data);
                        }
                    }

                } else {
                    this.workOrderList.map((item, index) => {
                        if (data.task_number === item.task_number) {
                            this.workOrderList.splice(index, 1);
                        }
                    });
                }
            }
        },
        // 车辆状态改变
        mapCarChange(data) {
            // 0 默认、1运输中、2到达 3 卸料、4 返场、 5 停止、6 未知、10 完成
            let findIndex = -1;
            this.workOrderList.map(item => {
                if (item.cars) {
                    item.cars.map((v, vIndex) => {
                        if (v.plate_number === data.plate_number) {
                            findIndex = 0;
                        }
                    });
                }
                this.carList.map(item => {
                    if (item.plate_number === data.plate_number) {
                        findIndex = 0;
                    }
                });
            });
            if (findIndex === -1) {
                if (data.task_number) {
                    this.workOrderList = this.workOrderList.map(item => {
                        if (item.task_number === data.task_number) {
                            if (data.status !== 0 && data.status !== 10) {
                                if (this.carActive === 0) {
                                    if (data.status !== 1) {
                                        item.cars.push(data);
                                    } else {
                                        this.carList.push(data);
                                    }
                                } else if (this.carActive === 1) {
                                    if (data.status !== 4) {
                                        item.cars.push(data);
                                    } else {
                                        this.carList.push(data);
                                    }
                                } else {
                                    item.cars.push(data);
                                }
                            }
                        }
                        return item;
                    });
                }
            } else if (data.status === 0 && data.status === 10) {
                this.workOrderList = this.workOrderList.map(item => {
                    if (item.cars) {
                        item.cars.map((v, vIndex) => {
                            if (v.plate_number === data.plate_number) {
                                item.cars.splice(vIndex, 1);
                            }
                        });
                    }

                    return item;
                });
                this.carList.map((item, index) => {
                    if (item.plate_number === data.plate_number) {
                        this.carList.splice(index, 1);
                    }
                });
            } else {
                this.workOrderList = this.workOrderList.map(item => {
                    if (item.cars) {
                        item.cars = item.cars.map(v => {
                            if (v.plate_number === data.plate_number) {
                                v = data;
                            }
                            return v;
                        });
                    }
                    return item;
                });
                this.carList = this.carList.map(item => {
                    if (item.plate_number === data.plate_number) {
                        item = data;
                    }
                    return item;
                });
            }
        },
        // 车辆状态改变-影响车辆返厂位置
        changeDis(data) {
            this.carList = this.carList.map(item => {
                if (item.plate_number === data.plate_number) {
                    item.target_distance = Number((data.target_distance / 1000).toFixed(2));
                }
                return item;
            });
        },
        // 车辆状态改变-影响车辆列表时间
        changeTrans(data) {
            this.transList = this.transList.map(item => {
                if (item.task_number === data.task_number) {
                    item.speed = data.speed;
                    item.to_distance = data.target_distance;
                    if (Number(item.speed) !== 0) {
                        item.to_time = (item.to_distance / 1000 / item.speed).toFixed(2);
                    }
                }
                return item;
            });
        },
        // 蚂蚁线查询
        handleSearchAntline() {
            this.handleProductionList();
        },
        // 异常列表-无限滚动
        load() {
            this.abnormalPage.currentPage++;
            this.handleExceptions();
        },
        // 顶部切换tab
        handleActiveTab(index) {
            this.activeTab = index;
            if (index === 0) {
                this.$emit('projectsClick', 1);
            } else {
                this.$emit('carClick', 1);
            }
            this.constructionActive = 0;
            this.carActive = -1;
            this.mainActiveTab = 0;
            this.handleCarlistPrj();
            this.handleProductionList();
            this.activeWorkOrder = '';
            this.$parent.getLocation();
        },
        // 工地类型切换
        hanleConstructionActive(index, item) {
            this.constructionActive = index;
            this.$emit('projectsClick', item.sortNum);
            this.projectCodeList = item.projectCodeList;
            this.handleCarlistPrj();
            this.handleProductionList();
            this.activeWorkOrder = '';
            this.$parent.getLocation();
        },
        // 车辆类型切换
        handleCarActive(index, num) {
            if (this.carActive === index) {
                this.carActive = -1;
                this.$emit('carClick', 1);
            } else {
                this.carActive = index;
                this.$emit('carClick', num);
            }
            this.handleCarlistPrj();
            this.handleProductionList();
            this.activeWorkOrder = '';
        },
        // 车辆列表类型切换
        handleTransActive(status) {
            this.transActive = status;
            this.handleCarlistPrj();
        },
        // 蚂蚁线、车辆列表切换
        handleMainActiveTab(index, data) {
            this.mainActiveTab = index;
        },
        // 获取车辆异常情况记录
        async handleExceptionsGroup() {
            const res = await this.getExceptionsGroup();
            if (res) {
                this.abnormalTabs = this.abnormalTabs.map(a => {
                    const temp = a.map(v => {
                        const arr = res.filter(item => item.error_type === v.sortNum);
                        v.num = arr[0].error_count;
                        return v;
                    });
                    return temp;
                });
                let sumCount = 0;
                res.map(item => {
                    sumCount += item.error_count;
                });
                this.tabs[2].name = '异常监控：' + sumCount;
            }
        },
        // 异常记录类型切换
        clickAbnormal(type) {
            if (this.abnormalActive !== type) {
                this.abnormalActive = type;
            } else {
                this.abnormalActive = -1;
            }
            this.tableData = [];
            this.abnormalPage.currentPage = 1;
            this.handleExceptions();
        },
        // 异常记录列表回车搜索
        handleAbnormalSearch() {
            this.tableData = [];
            this.handleExceptions();
        },
        // 异常记录列表
        async handleExceptions() {
            const res = await this.getExceptions();
            if (res) {
                this.tableData = [...this.tableData, ...res.rows];
                this.abnormalPage.total = res.total;
            }
        },
        // 处理蚂蚁线数据
        async handleProductionList() {
            let res = [];
            if (this.activeTab === 0) {
                res = await this.getProductionList();
            } else {
                res = await this.getProductionListCar();
            }
            if (res) {
                this.workOrderList = res.splice(0, 30);
                let taskNumbers = '';
                this.workOrderList.map((item, index) => {
                    taskNumbers += item.task_number + ',';
                    // eslint-disable-next-line radix
                    item.haul_distance_show = (item.haul_distance / 1000).toFixed(2);
                    item.half_haul_distance = (item.haul_distance / 2 / 1000).toFixed(2);
                    if (item.project_type === '103-FJ0') {
                        item.project_name = item.project_name + '| ' + item.monomer_name;
                    }
                    if (item.product_status !== 2) {
                        this.workOrderList.splice(index, 1);
                    }
                });
                this.carList = [];
                if (taskNumbers) {
                    const cars = await this.getTaskCars(taskNumbers);
                    if (cars) {
                        const map = {};
                        for (let i = 0; i < cars.length; i++) {
                            const ai = cars[i];
                            const obj = this.bd_encrypt(ai.longitude, ai.latitude);
                            ai.longitude = obj.bd_lng;
                            ai.latitude = obj.bd_lat;
                            // eslint-disable-next-line max-depth
                            if (!map[ai.task_number]) {
                                map[ai.task_number] = [];
                                // eslint-disable-next-line max-depth
                                if (ai.status === 0 || ai.status === 10) {
                                // map[ai.task_number] = [];
                                } else if (this.carActive === 0) {
                                    // eslint-disable-next-line max-depth
                                    if (ai.status !== 1) {
                                        map[ai.task_number].push(ai);
                                    } else {
                                        this.carList.push(ai);
                                    }
                                } else if (this.carActive === 1) {
                                    // eslint-disable-next-line max-depth
                                    if (ai.status !== 4) {
                                        map[ai.task_number].push(ai);
                                    } else {
                                        this.carList.push(ai);
                                    }
                                } else {
                                    map[ai.task_number].push(ai);
                                }
                            } else {
                                // eslint-disable-next-line no-lonely-if max-depth
                                // eslint-disable-next-line
                                if (ai.status === 0 || ai.status === 10) {
                                    // map[ai.task_number] = [];
                                } else if (this.carActive === 0) {
                                    // eslint-disable-next-line max-depth
                                    if (ai.status !== 1) {
                                        map[ai.task_number].push(ai);
                                    } else {
                                        this.carList.push(ai);
                                    }
                                } else if (this.carActive === 1) {
                                    // eslint-disable-next-line max-depth
                                    if (ai.status !== 4) {
                                        map[ai.task_number].push(ai);
                                    } else {
                                        this.carList.push(ai);
                                    }
                                } else {
                                    map[ai.task_number].push(ai);
                                }
                            }
                        }
                        this.workOrderList = this.workOrderList.map(item => {
                            if (map[item.task_number]) {
                                item.cars = map[item.task_number];
                            } else {
                                item.cars = [];
                            }
                            return item;
                        });
                    }
                }
            }
        },
        // 车辆状态
        getClass(data) {
            let str = 'car';
            if (data.orientation === 0) {
                str += ' to-car';
            } else {
                str += ' back-car';
            }
            switch (data.status) {
                case 1:
                    str += ' trans-car';
                    break;
                case 2:
                    str += ' arrive-car';
                    break;
                case 3:
                    str += ' discharge-car';
                    break;
                case 4:
                    str += ' return-car';
                    break;
                case 5:
                    str += ' stop-car';
                    break;
                case 10:
                    str += ' finish-car';
                    break;
                default:
            }
            return str;
        },
        // 计算车辆位置
        computeDis(item, car) {
            let dis = 0;
            if (car.orientation === 0) {
                if (car.to_distance <= item.haul_distance) {
                    dis = (car.to_distance / item.haul_distance * 100).toFixed(2);
                } else {
                    dis = 95;
                }
            } else if (car.orientation === 1) {
                if (car.back_distance <= item.haul_distance) {
                    dis = (car.back_distance / item.haul_distance * 100).toFixed(2);
                } else {
                    dis = 5;
                }
            }
            return dis;
        },
        // 完成率
        getFinishInner(data) {
            let finishRate = 0;
            if (data.plan_quantity) {
                let rate = (data.grand_total_quantity / data.plan_quantity * 100).toFixed(2);
                if (rate > 100) {
                    rate = 100;
                }
                finishRate = rate + '%';
            }
            return finishRate;
        },
        // 车辆返场-距离终点距离
        getCarLeft(data) {
            let styleStr = { left: 0, marginLeft: 0 };
            let rate = 0;
            rate = (Number(data.target_distance) / 30).toFixed(2);
            if (Number(rate) === 0) {
                styleStr = { left: rate * 100 + '%', marginLeft: '.1rem' };
            } else if (Number(rate) >= 100) {
                rate = '100%';
                styleStr = { left: '100%', marginLeft: '-.1rem' };
            } else {
                styleStr = { left: rate * 100 + '%', marginLeft: '-.1rem' };
            }
            return styleStr;
        },
        // 车辆返场-车辆状态
        getCarClass(data) {
            let str = 'car';
            if (data.status === 1) {
                str += '  car-trans';
            } else if (data.status === 4) {
                str += ' car-back';
            }
            return str;
        },
        // 处理在施工地下车辆列表
        async handleCarlistPrj() {
            let res = [];
            if (this.activeTab === 0) {
                res = await this.getCarlistPrj();
            } else {
                res = await this.getCarlistCarriage();
            }
            if (res) {
                this.transList = res;
            }
        },
        // 点击蚂蚁线显示地图车辆
        async clickWorkOrder(task_number) {
            this.activeWorkOrder = task_number;
            const res = await this.getAntLineCars();
            if (res) {
                const markersArr = [];
                res.forEach((item, index) => {
                    if (item.status !== 9) {
                        let markersObj = {};
                        if (item.longitude && item.latitude) {
                            const parseIcon = this.$parent.getIcon(item);
                            // 车辆信息
                            markersObj = {
                                carName: item.plate_number,
                                plate_small_number: item.plate_small_number,
                                position: { lng: item.longitude, lat: item.latitude },
                                numberOffset: [],
                                icon: parseIcon.icon,
                                size: parseIcon.size,
                                offset: parseIcon.offset,
                                visible: false,
                                type: '',
                                status: item.status,
                                rotation: item.direction,
                            };
                            if ([6].indexOf(item.status) > -1) {
                                markersObj.type = 'trans';
                            } else if ([7, 73].indexOf(item.status) > -1) {
                                markersObj.type = 'onSite';
                            } else if ([77, 8].indexOf(item.status) > -1) {
                                markersObj.type = 'back';
                            } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                                markersObj.type = 'notWork';
                            }
                            markersArr.push(markersObj);
                        }
                    }
                });
                this.$parent.oldCars = JSON.parse(JSON.stringify(markersArr));
                this.$parent.markers = markersArr;
            }
        },
        // 车辆列表点击事件 - 聚焦地图上该车辆
        clickTransList(row, column, event) {
            // this.activeList = index;
            const arr = this.$parent.markers.filter(item => item.plate_small_number === row.plate_small_number);
            if (arr.length > 0) {
                this.$parent.mapCenter = arr[0].position;
            }
        },
        // 保存异常参数设置
        saveExceptionVal() {
            const arr = [];
            for (const key in this.exceptionVal) {
                arr.push({
                    TypeEnum: key,
                    val: this.exceptionVal[key],
                });
            }
            this.$axios.post('/interfaceApi/logistics/car_manager/exception_set', arr, { defEx: true })
                .then(() => {
                    this.$message.success('设置成功');
                    this.exceptionSetClose();
                });
        },
        // 打开异常设置
        openExceptionSet() {
            this.$axios.get('/interfaceApi/logistics/car_manager/exception_set_info', { defEx: true })
                .then(data => {
                    if ((data || []).length > 0) {
                        data.forEach(item => {
                            // eslint-disable-next-line no-undefined
                            if (this.exceptionVal[item.TypeEnum] !== undefined) {
                                this.exceptionVal[item.TypeEnum] = item.val;
                            }
                        });
                    }
                    this.showExceptionSet = true;
                });
        },
        // 关闭异常设置
        exceptionSetClose() {
            this.showExceptionSet = false;
            this.exceptionVal = this.$options.data().exceptionVal;
        },
        // 拉取运输中车辆
        getTaskCars(taskNumbers) {
            return this.$axios.get('/interfaceApi/production/producttask/get_task_cars?task_number=' + taskNumbers);
        },
        // 拉取在施工地蚂蚁线
        getProductionList() {
            const path = '/interfaceApi/production/producttask/get_production_list?stationCode='
            + this.currentStationId + '&product_status=' + this.constructionActive + '&keywords=' + this.searchVal;
            return this.$axios.$get(path, { defEx: true });
        },
        // 运输单详情
        transInfo(data) {
            this.$toast({
                title: true,
                text: '发货单详情',
                type: 'eject',
                width: '10rem',
                height: '6rem',
                t_url: 'productionManagement/logisticsMonitoring/billInfo',
                viewPosition: 'view',
                extr: { pcb_number: data },
            });
        },
        // 在施工地模块下拉取车辆信息
        getCarlistPrj() {
            const data = {
                keywords: this.transSearchVal,
                stationId: this.currentStationId,
                project_status: this.constructionActive,
                project_code_list: this.projectCodeList,
                vehicle_status: this.transActive,
            };
            const path = '/interfaceApi/production/vehicleinfo/get_carlist_prj';
            return this.$axios.$post(path, data, { defEx: true });
        },
        // 拉取运输车辆-蚂蚁线
        getProductionListCar() {
            const path = '/interfaceApi/production/producttask/get_production_list_car?stationCode='
            + this.currentStationId + '&car_status=' + this.carActive + '&keywords=' + this.searchVal;
            return this.$axios.$get(path, { defEx: true });
        },
        // 拉取运输车辆-车辆列表
        getCarlistCarriage() {
            const data = {
                keywords: this.transSearchVal,
                stationId: this.currentStationId,
                carriage_status: this.carActive,
            };
            const path = '/interfaceApi/production/vehicleinfo/get_carlist_carriage';
            return this.$axios.$post(path, data, { defEx: true });
        },
        // 拉取蚂蚁线下地图车辆
        getAntLineCars() {
            const carType = 0;
            const path = '/interfaceApi/production/vehicleinfo/get_location?stationId='
            + `${this.currentStationId}&carType=${carType}&keywords=${this.$parent.searchInfo}&task_number=${this.activeWorkOrder}`;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取车辆异常情况记录
        getExceptionsGroup() {
            const path = '/interfaceApi/logistics/car_manager/get_exceptions_group';
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取车辆异常情况记录
        getExceptions() {
            const path = '/interfaceApi/logistics/car_manager/get_exceptions?keywords=' + this.abnormalSearch
            + '&pageindex=' + this.abnormalPage.currentPage + '&pagesize=' + this.abnormalPage.pageSize
            + '&type=' + this.abnormalActive;
            return this.$axios.$get(path, { defEx: true });
        },

    },
};
</script>
<style lang="stylus">
.abnormalinfo-tooltip
    background #5B7EB7!important
    box-shadow 2px 2px 4px 0px rgba(0, 0, 0, 0.3)
    .popper__arrow
        &::after
            border-left-color #5B7EB7!important
.logist-actionsPanel
    position absolute
    right .2rem
    top .2rem
    height calc(100% - .4rem)
    overflow inherit
    display: flex;
    flex-direction: column;
    .tab-con
        overflow hidden
        .tab-conta
            float right
            min-height 1.28rem
            background #314663
            box-shadow 2px 2px 6px 0px rgba(0, 0, 0, 0.3)
            border-radius 2px
            margin-bottom .1rem
            width 4.6rem
    &.logist-actionsPanel-hidden
        overflow hidden
    .tabs
        display flex
        .item
            flex 1
            font-size .14rem
            color #ffffff
            text-align center
            height .32rem
            line-height .32rem
            background #354D6C
            position relative
            cursor pointer
            .iconfont
                margin-right .05rem
                vertical-align middle
            &.active
                background #1890FF
                &:before
                    content ''
                    position absolute
                    width 0
                    height 0
                    border 10px solid transparent
                    border-top-color #1890FF
                    bottom -15px
                    left 50%
                    margin-left -5px
    .contents
        padding .1rem
        .item
            >ul
                display flex
            .tab-item
                margin-top .1rem
                width 1.02rem
                height .56rem
                background #354D6C
                margin-right .1rem
                text-align center
                cursor pointer
                .num
                    font-size .24rem
                    font-family technology
                    padding .02rem 0 .02rem 0
                .iconfont
                    text-shadow 0 0 0 #fff;
                    font-size .12rem
                    margin-right .03rem
                    color #FF6E77
                p
                    &:nth-child(2)
                        color #fff
                        font-size .12rem
            &.item1
                li
                    &:nth-child(1)
                        .num
                            color #15BC64
                        .iconfont
                            color #16B380
                    &:nth-child(2)
                        .num
                            color #1897FF
                        .iconfont
                            color #1897FF
                    &:nth-child(3)
                        .num
                            color #FF6E77
                        .iconfont
                            color #FF6E77
                    &.active
                        background #15BC64
                        .num
                            color #FFF
            &.item2
                li
                    &:nth-child(1)
                        .num
                            color #1897FF
                        .iconfont
                            color #1897FF
                    &:nth-child(2)
                        .num
                            color #F98C47
                        .iconfont
                            color #F98C47
                    &:nth-child(3)
                        .num
                            color #15BC64
                        .iconfont
                            color #15BC64
                    &:nth-child(4)
                        .num
                            color #FF6E77
                        .iconfont
                            color #FF6E77
                    &.active
                        background #F98C47
                        .num
                            color #FFF
                        .iconfont
                            color #fff
            &.item3
                .abnormal-box
                    margin 0.1rem 0;
                    width 100%;
                    display flex
                    justify-content space-between;
                    align-items center;
                    &.carWatch
                        height 1.32rem
                    &.projectWatch
                        min-height 1rem
                    .title
                        width 0.3rem
                        height 100%;
                        display flex
                        flex-direction column
                        justify-content center
                        align-items center
                        text-align center
                        background #276FA6
                        color #fff;
                        font-size 0.16rem;
                ul
                    display flex
                    flex-wrap wrap
                    flex 1
                .tab-item
                    width 0.92rem;
                    margin-left 0.1rem;
                    margin-right 0;
                    margin-top 0.1rem;
                    .num
                        color #FF6E77
                        padding 0;
                    &.active
                        background #FF6E77
                        .num
                            color #FFF
                        .iconfont
                            color #fff
    .open-btn
        position absolute
        top 30%
        left -.21rem
        width .23rem
        height .52rem
        background url(../images/btn_back_r.png) no-repeat 100% 100%
        cursor pointer
    .close-btn
        right 0
        background url(../images/btn_back_l.png) no-repeat 100% 100%
        left inherit
    .main
        position absolute
        right 0
        top 1.38rem
        height calc(100% - 1.38rem)
        background #3A5173
        box-shadow 2px 2px 6px 0px rgba(0, 0, 0, 0.3)
        border-radius 2px
        width 4.6rem
        // transition  1s
        .content
            height calc(100% - .32rem)
            .antline
                padding .1rem
                height 100%
                .top
                    width 100%
                    .el-input
                        width 60%
                        .el-input__inner
                            background #314663
                            height .32rem
                            line-height .32rem
                            border 1px solid #314663
                            color #fff
                            &::-webkit-input-placeholder
                                font-size .14rem
                            &::-moz-placeholder
                                font-size .14rem
                            &::-ms-input-placeholder
                                font-size .14rem
                    .el-radio-group
                        margin-left .1rem
                        .el-radio__label
                            padding-left .05rem
                            font-size .14rem
                        .el-radio
                            color #82A7DF
                        .el-radio__input
                            &.is-checked+.el-radio__label
                                color #fff
                        .el-radio__inner
                            background: rgba(0, 0, 0, 0.04);
                            // &::after
                            //     background #82A7DF
                .antline-con
                    margin-top .1rem
                    height calc(100% - .96rem)
                    overflow-y auto
                    &.haveBackCar
                        height calc(100% - 1.66rem)
                    li
                        width 100%
                        padding .1rem
                        border-radius 2px
                        border 1px solid #50709F
                        margin-bottom .1rem
                        color #ffffff
                        font-size .12rem
                        cursor pointer
                        .order-num
                            width .18rem
                            height .18rem
                            text-align center
                            line-height .18rem
                            display inline-block
                            background #0189FD
                            border-radius 50%
                            margin-right .05rem
                        .line-con
                            display flex
                            justify-content space-between
                            >div
                                &:nth-child(1)
                                    width calc(100% - 1rem)
                                    border-bottom 1px solid #FFFFFF
                                    margin .3rem 0 .3rem .25rem
                                    position relative
                                    margin-right .2rem
                                    .start-icon,.end-icon
                                        position absolute
                                        background-repeat no-repeat
                                        background-size 100% 100%
                                        width .32rem
                                        height .32rem
                                        top -.16rem
                                    .start-icon
                                        left -.16rem
                                        background-image url(../images/cz.png)
                                    .end-icon
                                        right -.26rem
                                        top -.28rem
                                        background-image url(../images/flag.png)
                                    &:before
                                        content ''
                                        position absolute
                                        width 0
                                        height 0
                                        border .05rem solid transparent
                                        border-bottom-color #1890FF
                                        bottom -.08rem
                                        left 50%
                                        margin-left -.05rem
                                    .start-num,.middle-num,.end-num
                                        position absolute
                                        top .1rem
                                    .start-num
                                        left 0
                                    .middle-num
                                        left 50%
                                        margin-left -.15rem
                                    .end-num
                                        right -.2rem
                                    .car
                                        width .3rem
                                        height .18rem
                                        background #0091FF
                                        border-radius 2px
                                        border 1px solid #FFFFFF
                                        position absolute
                                        left .2rem
                                        top 0
                                        text-align center
                                        line-height .18rem
                                    .to-car
                                        top -.18rem
                                    .back-car
                                        top 0
                                    .trans-car
                                        background #0048B8
                                    .stop-car
                                        background #D6010F
                                    .discharge-car
                                        background #7B36C9
                                    .return-car
                                        background #FF7E00
                                    .arrive-car
                                        background #20AD52
                                &:nth-child(2)
                                    width 0.85rem
                                    overflow hidden
                                    margin-top 0.1rem
                                    margin-left 0.02rem
                                    .car
                                        float left
                                        width .34rem
                                        height .18rem
                                        background #007E00
                                        border-radius 2px
                                        border 1px solid #FFFFFF
                                        margin 0 .02rem .02rem 0
                                        text-align center
                                        padding 0 .01rem
                                        .trans-car
                                            background #0048B8
                                        .stop-car
                                            background #D6010F
                                        .discharge-car
                                            background #7B36C9
                                        .return-car
                                            background #FF7E00
                                        .arrive-car
                                            background #20AD52
                                &:nth-child(3)
                                    .parent
                                        width .08rem
                                        height .40rem
                                        border 1px solid #FFFFFF
                                        position relative
                                        box-sizing border-box
                                        margin-top .1rem
                                        .per
                                            width .06rem
                                            height .2rem
                                            background linear-gradient(180deg, #76E0A8 0%, #13BD63 100%)
                                            position absolute
                                            left 0
                                            bottom 0
                        &.active
                            background #293A53
                .bottom-scale
                    position absolute
                    bottom 0
                    left 0
                    width 100%
                    .title
                        display flex
                        color #fff
                        font-size .12rem
                        line-height .2rem
                        background #1890FF
                        .left,.right
                            width calc((100% - .6rem) / 2)
                            border-top 1px dashed #fff
                            margin-top .1rem
                        >span
                            &:nth-child(2)
                                margin 0 .05rem
                    .scale-con
                        height .52rem
                        padding 0 .2rem
                        background #415C81
                        .scale
                            display flex
                            position relative
                            span
                                width calc(100% / 60)
                                position relative
                                height 4px
                                margin-top .1rem
                                &:after
                                    content ''
                                    left 0
                                    top 0
                                    position absolute
                                    background #fff
                                    height 4px
                                    width 1px
                                &.height8
                                    &:after
                                        height 8px
                                &.height10
                                    &:after
                                        height 10px
                                .number
                                    position absolute
                                    font-size 12px
                                    color #fff
                                    width .2rem
                                    left -.1rem
                                    text-align center
                                    top .1rem
                            .car
                                position absolute
                                left 10%
                                background-repeat no-repeat
                                background-size 100% 100%
                                width .2rem
                                height .2rem
                                top .3rem
                                margin-left -.1rem
                            .car-trans
                                background-image url(../images/vehicle_1.png)
                            .car-back
                                background-image url(../images/vehicle_2.png)
                    .legends
                        padding .1rem .1rem 0 .1rem
                        background #314663
                        display flex
                        flex-wrap wrap
                        .item
                            flex-shrink 0
                            width 20%
                            font-size .12rem
                            color #fff
                            margin-bottom .1rem
                            .pre-icon
                                width .1rem
                                height .1rem
                                background #007E00
                                border 1px solid #fff
                                border-radius 1px
                                display inline-block
                                margin-right .05rem
                        .item0
                            .pre-icon
                                background #0048b8
                        .item1
                            .pre-icon
                                background #ff7e00
                        .item2
                            .pre-icon
                                background #d6010f
                        .item3
                            .pre-icon
                                background #9152d8
                        .item4
                            .pre-icon
                                background #20ad52
                        .item5
                            .pre-icon
                                background #c8c8c8
            .carlist
                height 100%
                .tabs
                    display flex
                    margin .1rem .2rem 0 .2rem
                    .tab
                        flex 1
                        color #fff
                        font-size .12rem
                        text-align center
                        height .3rem
                        line-height .3rem
                        &.active
                            background #314663
                            border-radius 5px 5px 0 0
                .search-con
                    margin 0 .2rem .1rem .2rem
                    .el-input
                        width 100%
                        .el-input__inner
                            background #314663
                            height .32rem
                            line-height .32rem
                            border 1px solid #314663
                            color #fff
                            &::-webkit-input-placeholder
                                font-size .14rem
                            &::-moz-placeholder
                                font-size .14rem
                            &::-ms-input-placeholder
                                font-size .14rem
                .list-con
                    // height calc(100% - 1rem)
                    // overflow-y auto
                    .el-table
                        background-color #3A5173
                        &::before
                            background-color #3A5173
                        thead
                            color #fff
                            tr
                                background-color #435E86
                                th
                                  padding .06rem 0
                                  background-color #435E86
                                  font-weight normal
                        .el-table__body
                            tr
                                >td
                                    background-color #3A5173
                                    color #C3DBFF
                                &.current-row
                                    >td
                                        background-color #293A53
                                        color #FF7E00
                        td, th
                            border-bottom 1px solid #435E86
                            text-align center
                        .pcb-num
                            color #18B6FF
                            text-decoration underline
                        table
                            width auto !important;
                            /deep/ .cell
                                font-size 0.14rem;
                        .el-table__empty-block
                            width auto!important;

            .list-con
                .tit
                    display flex
                    background #435E86
                    height .4rem
                    font-size .14rem
                    color #fff
                    li
                        text-align center
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
                        &:nth-child(1)
                            width .8rem
                            line-height .4rem
                        &:nth-child(2)
                            width .7rem
                        &:nth-child(3)
                            width .7rem
                        &:nth-child(4)
                            width .7rem
                        &:nth-child(5)
                            width calc(100% - 2.7rem)
                            line-height .4rem
                .list
                    li
                        display flex
                        border-bottom 1px solid #435E86
                        cursor pointer
                        span
                            text-align center
                            line-height .4rem
                            font-size .14rem
                            color #fff
                            white-space nowrap
                            overflow hidden
                            text-overflow ellipsis
                            padding 0 .1rem
                            &:nth-child(1)
                                width .8rem
                                color #FF7E00
                            &:nth-child(2)
                                width .7rem
                            &:nth-child(3)
                                width .7rem
                            &:nth-child(4)
                                width .7rem
                            &:nth-child(5)
                                width calc(100% - 2.7rem)
                                color #18B6FF
                                text-decoration underline
                        &.active
                            background #293A53
        &.doubleRow
            width 9.2rem
            .main-box
                width 9.2rem
            .content
                .antline
                    .antline-con
                        ul
                            display flex
                            flex-wrap wrap
                            li
                                width calc(50% - .1rem)
                                flex-shrink 0
                                margin 0 .05rem .1rem
                    .bottom-scale
                        .legends
                            .item
                                width 11%
        &.close
            width 0
            // transition  0.5s
    .abnormal-main
        /*position absolute*/
        height 3.8rem;
        right 0
        top 1.38rem
        background #3A5173
        box-shadow 2px 2px 6px 0px rgba(0, 0, 0, 0.3)
        border-radius 2px
        width 4.6rem
        padding .2rem
        .el-input
            margin-bottom .1rem
        .el-input__inner
            background-color #314663
            border 1px solid #314663
            color #fff
            &::-webkit-input-placeholder
                font-size .14rem
            &::-moz-placeholder
                font-size .14rem
            &::-ms-input-placeholder
                font-size .14rem
        .abnormal-list
            height calc(100% - .46rem)
            overflow-y auto
            ul
                margin-bottom .1rem
            li
                display flex
                justify-content space-between
                padding .08rem
                border 1px solid #50709F
                font-size .14rem
                color #fff
                .plate-small-num
                    width .36rem
                    height .24rem
                    display inline-block
                    line-height .24rem
                    text-align center
                    background #FF7E00
                    font-size .14rem
                    color #fff
                    margin-right .1rem
                .time
                    display flex
                    margin-top .03rem
                    .dot
                        display inline-block
                        width .06rem
                        height .06rem
                        background #FFFFFF
                        border-radius 50%
                        margin .05rem .1rem 0 0
        .el-pagination
            .el-pagination__total
               color #82A7DF
    .exceptionSetBtn
        position: absolute;
        right: 0.12rem;
        top: 0.36rem;
        color: #FFFFFF;
        cursor pointer
    .exception-rules-dialog
        position fixed
        width 100%
        height 100%
        left 0
        top 0
        background rgba(0,0,0,0.5);
        display flex
        justify-content center
        align-items center
        z-index 99
        .rules-content
            padding 0 0.3rem 0.3rem 0.3rem
            background #fff;
            border 10px solid rgba(255,255,255,0.6);
            h2
                font-size 0.2rem
                color #333333
                margin-top 0.1rem
                margin-bottom 0.1rem;
            .rules-item
                display flex
                align-items center
                padding 0.09rem
                label
                    font-size 0.16rem;
                    color #666666
                .el-input
                    width 2.6rem
                    margin 0 0.06rem;
            .btns
                margin-top 0.15rem
                width 100%;
                display flex
                justify-content space-around
                a
                    width: 1.3rem
                    height 0.36rem;
                    border-radius 1px;
                    line-height 0.36rem;
                    text-align center
                    font-size 0.16rem;
                    cursor pointer
                .btn-save
                    background #1577D2
                    color #fff
                .btn-close
                    border: 1px solid #1577D2;
                    color #1577D2
</style>
